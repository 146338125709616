import {Component, ViewContainerRef, OnInit, OnDestroy, HostListener} from '@angular/core';
import {Subscription} from 'rxjs';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {filter} from 'rxjs/operators';
import {AuthService} from './shared/auth/auth.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  organizationGroup: any;

  constructor(private router: Router, private translateService: TranslateService,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService) {
  }

  ngOnInit() {
    if (Object.keys(localStorage).includes('organizationGroup')) {
      this.organizationGroup = JSON.parse(localStorage.getItem('organizationGroup')).data;
    }
    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => window.scrollTo(0, 0));
    this.activatedRoute.queryParams.subscribe((resp: any) => {
      localStorage.setItem('organization', resp.orgId);
      // @ts-ignore
    });
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('window:mousemove') onMouseMove() {
    this.authService.resetTimer();
  }

  @HostListener('window:click') onClick() {
    this.authService.resetTimer();
  }

  @HostListener('window:keypress') onKeyPress() {
    this.authService.resetTimer();
  }
}
