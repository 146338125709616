import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {switchMap, tap} from 'rxjs/operators';
import {AuthService} from '../auth/auth.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  // handleResponseError(http, request?, next?) {
  //   // Business error
  //   if (HttpResponse.status === 400) {
  //     // Show message
  //   }
  //
  //   // Invalid token error
  //   else if (error.status === 401) {
  //     return this.refreshToken().pipe(
  //       switchMap(() => {
  //         request = this.addAuthHeader(request);
  //         return next.handle(request);
  //       }),
  //       catchError(e => {
  //         if (e.status !== 401) {
  //           return this.handleResponseError(e);
  //         } else {
  //           this.logout();
  //         }
  //       }));
  //   }
  //
  //   // Access denied error
  //   else if (error.status === 403) {
  //     // Show message
  //     // Logout
  //     this.logout();
  //   }
  //
  //   // Server error
  //   else if (error.status === 500) {
  //     // Show message
  //   }
  //
  //   // Maintenance error
  //   else if (error.status === 503) {
  //     // Show message
  //     // Redirect to the maintenance page
  //   }
  //
  //   return throwError(error);
  // }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone();
    return next.handle(request).pipe(
      tap({
        next: (event) => {
          if (event instanceof HttpResponse) {
            if (event.status === 401) {
              console.log('Unauthorized access!');
            }
          }
          return event;
        },
        error: (error) => {
          if (error.status === 401) {
            console.log('Unauthorized access!');
            this.router.navigate(['/login-admin']);
            if (this.auth.userData.auth.refreshToken) {
              // return this.auth.refreshAuthToken();
              localStorage.clear();
              this.router.navigate(['/login-admin']);
            }
            // return true
          } else if (error.status === 404) {
            console.log('Page Not Found!');
          }
        }
      }));
  }

  constructor(private auth: AuthService, private router: Router) {
  }
}
