import {RouteInfo} from './vertical-menu.metadata';
import {pendingAppointments} from './vertical-menu-routes.config';

// Sidebar menu Routes and data
// ALWAYS FOLLOW THIS STRUCTURE SuperAdmin -> Admin -> Staff -> User
export let PULSERIGHT_ROUTES: RouteInfo[] = [
  {
    path: '/list-services',
    title: 'Hospital Services',
    icon: 'ft-briefcase',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Super Admin', 'Admin', 'Staff', 'Manager']
  },
  {
    path: '/departments',
    title: 'Departments',
    icon: 'fa-solid fa-building-user',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Admin', 'Manager']
  },
  {
    path: '/list-doctors',
    title: 'Doctors',
    icon: 'fa-solid fa-user-doctor',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Super Admin', 'Admin', 'Staff', 'Manager']
  },

  {
    path: '/doctor-appointments',
    title: 'My Appointments',
    icon: 'fa-solid fa-user-doctor',
    class: 'dropdown',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Doctor']
  },
  {
    path: '/doctor-queue-lists',
    title: 'My Queue List',
    icon: 'ft-book',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Doctor']
  },
  {
    path: '',
    title: 'Patients',
    icon: 'ft-user-plus',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/list-patients',
        title: 'List Patients',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: ['Super Admin', 'Admin', 'Staff', 'Manager', 'Doctor']
      },
    ],
    category: ['Super Admin', 'Admin', 'Staff', 'Manager', 'Doctor']
  },
  {
    path: '/immunization',
    title: 'Immunization',
    icon: 'fa-solid fa-syringe',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Super Admin', 'Admin', 'Manager']
  },
  {
    path: '',
    title: 'Appointments',
    icon: 'ft-clipboard',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/list-appointments',
        title: 'List Appointments',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: pendingAppointments,
        badgeClass: 'badge badge-pill badge-danger mt-1 mr-2 no-right',
        isExternalLink: false,
        submenu: [],
        category: ['Super Admin', 'Admin', 'Staff', 'Manager']
      },
      {
        path: '/create-appointments',
        title: 'Create Appointments',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: ['Super Admin', 'Admin', 'Staff', 'Manager']
      },
    ],
    category: ['Super Admin', 'Admin', 'Staff', 'Manager']
  },
  {
    path: '/queue-list',
    title: 'Queue List',
    icon: 'fa-solid fa-people-roof',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Super Admin', 'Admin', 'Staff', 'Manager']
  },
  {
    path: '',
    title: 'User Management',
    icon: 'ft-users',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/list-staff',
        title: 'List Staff',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: ['Super Admin', 'Admin', 'Staff', 'Manager']
      }, {
        path: '/list-admins',
        title: 'List Managers',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: ['Super Admin', 'Admin', 'Manager']
      },
      {
        path: '/list-super-admins',
        title: 'List Super Admins',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: ['Super Admin', 'Admin']
      },
    ],
    category: ['Admin', 'Super Admin', 'Staff', 'Manager']
  }, {
    path: '',
    title: 'Leave Tracker',
    icon: 'fa-solid fa-business-time',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [{
      path: '/staff-leave',
      title: 'Apply Leave',
      icon: 'ft-arrow-right submenu-icon',
      class: 'dropdown nav-item',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: [],
      category: ['Staff', 'Manager']
    }, {
      path: '/staff-leave-requests',
      title: 'Leave Requests',
      icon: 'ft-arrow-right submenu-icon',
      class: 'dropdown nav-item',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: [],
      category: ['Staff', 'Manager', 'Admin']
    }, {
      path: '/staff-attendance-report',
      title: 'Attendance Report',
      icon: 'ft-arrow-right submenu-icon',
      class: 'dropdown nav-item',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: [],
      category: ['Manager', 'Admin']
    }],
    category: ['Staff', 'Manager', 'Admin']
  },
  {
    path: '/bill-generation',
    title: 'Invoice Slips',
    icon: 'fa-solid fa-credit-card',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Super Admin', 'Admin', 'Manager']
  },
  {
    path: '/organization-group',
    title: 'Organization Group',
    icon: 'fa-solid fa-home',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Super Admin', 'Admin']
  }, {
    path: '/modules',
    title: 'Modules',
    icon: 'fa-solid fa-list-check',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Super Admin', 'Admin']
  },
  {
    path: '',
    title: 'Pharmacies',
    icon: 'fa-solid fa-suitcase-medical',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [{
      path: '/pharmacy-user-management',
      title: 'User Management',
      icon: 'ft-users',
      class: 'dropdown nav-item',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: [],
      category: ['Super Admin', 'Admin']
    }, {
      path: '/list-pharmacy',
      title: 'List Pharmacy',
      icon: 'ft-list',
      class: 'dropdown nav-item',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: [],
      category: ['Super Admin', 'Admin']
    }],
    category: ['Super Admin', 'Admin']
  },
  {
    path: '/orders',
    title: 'Orders',
    icon: 'fa-brands fa-first-order',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Pharmacy Admin']
  },

  {
    path: '/orders-history',
    title: 'Orders History',
    icon: 'fa-solid fa-clock-rotate-left',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Pharmacy Admin']
  },
  {
    path: '',
    title: 'Inventory',
    icon: 'fa-solid fa-warehouse',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [{
      path: '/pharmacy-stocks',
      title: 'Pharmacy Stocks',
      icon: 'ft-arrow-right submenu-icon',
      class: 'dropdown nav-item',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: [],
      category: ['Pharmacy Admin']
    }, {
      path: '/global-stocks',
      title: 'Global Stocks',
      icon: 'ft-arrow-right submenu-icon',
      class: 'dropdown nav-item',
      badge: '',
      badgeClass: '',
      isExternalLink: false,
      submenu: [],
      category: ['Pharmacy Admin']
    },],
    category: ['Pharmacy Admin']
  },
  {
    path: '/pharmacy-info',
    title: 'Pharmacy Info',
    icon: 'fa-solid fa-suitcase-medical',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Pharmacy Admin']
  },
  {
    path: '/pharmacy-user-management',
    title: 'Pharmacy Users',
    icon: 'ft-users',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Pharmacy Admin']
  },
  {
    path: '/prescription-template',
    title: 'Template',
    icon: 'fa-solid fa-clipboard',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Doctor']
  },
  {
    path: '/marketing',
    title: 'Marketing',
    icon: 'ft-message-square',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Admin', 'Manager', 'Super Admin']
  }, {
    path: '',
    title: 'Pulseright Ads',
    icon: 'ft-airplay',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [
      {
        path: '/campaigns',
        title: 'Campaigns',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown nav-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: ['Admin', 'Super Admin', 'Manager']
      },
      {
        path: '/clients',
        title: 'Clients',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown nav-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        category: ['Admin', 'Super Admin', 'Manager']
      }
    ],
    category: ['Admin', 'Super Admin', 'Manager']
  },
  // {
  //   path: '/patient-bill-template',
  //   title: 'Bill Template',
  //   icon: 'ft-message-square',
  //   class: 'dropdown nav-item',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   submenu: [],
  //   category: ['Admin', 'Super Admin']
  // },
  {
    path: '/settings',
    title: 'Settings',
    icon: 'fa-solid fa-gear',
    class: 'dropdown nav-item',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    category: ['Super Admin', 'Admin', 'Manager']
  },
];

